/* TableItemList
 * Props:
 *   title: the title of the table
 *   header: a list of column titles
 *   items: a list of row info, where each row is a list of strings
 *
 * Usage:
 *   <TableItemList
 *     title={"Services"}
 *     header={["Service", "Price"]}
 *     items={[
 *       ["Adult haircut", "$20"],
 *       ["Kids haircut", "$15"],
 *     ]}
 *   />
 *
 * Note:
 *   We use indexes as keys since the data is static
 *   and we want to enable passing in jsx cell data
 */

import React from "react"
import styled from "styled-components"

const StyledTable = styled.table`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  // No exterior border, overrides cell settings
  border-style: hidden;
  // No space between cells
  border-collapse: collapse;

  caption {
    font-size: large;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-weight: normal;
    padding-bottom: 7px;
  }

  tr.light-colored-row {
    background-color: var(--themedBackgroundColor);
  }

  tr.dark-colored-row {
    background-color: var(--darkBackgroundColor);
    a {
      color: var(--darkLinkColor);
    }
  }

  th {
    vertical-align: top;
    text-align: left;
    font-style: italic;
    font-weight: normal;
    background-color: var(--themedBackgroundColor);
    // Interior header borders
    border: solid var(--accentColor);
    border-bottom: thick double var(--accentColor);
    padding: 7px;
  }

  td {
    vertical-align: top;
    // Cell borders between columns
    border-left: solid var(--accentColor);
    border-right: solid var(--accentColor);
    padding: 7px;
  }

  .notes {
    width: 100%;
    font-size: small;
  }
`

export default function TableItemList(props) {

  const headerJSX = header => header.map((columnHeader, idx) =>
    <th key={idx}>{columnHeader}</th>
  );

  const itemJSX = item => item.map((cellInfo, idx) =>
    <td key={idx}>{cellInfo}</td>
  );

  const allItemsJSX = (items, coloredRowIdxModTwo) => items.map((row, idx) =>
    <tr
      key={idx}
      className={`${idx % 2 === coloredRowIdxModTwo ? "light-colored-row" : "dark-colored-row"}`}
    >
      {itemJSX(row)}
    </tr>
  );

  // Start coloring rows on the second row if there's a header, otherwise the first row
  const coloredRowIdxModTwo = !!props.header ? 1 : 0

  return (
    <StyledTable data-qa={props["data-qa"] || "TableItemList"}>
      <caption>{props.title}</caption>
      {props.header && <thead>
        <tr>
          {headerJSX(props.header)}
        </tr>
      </thead>}
      <tbody>
        {allItemsJSX(props.items, coloredRowIdxModTwo)}
      </tbody>
    </StyledTable>
  )
}
