/*  form-manipulation.js
 *
 *  moveFieldsToMessage
 *    params:
 *      formData: FormData
 *      fields: array of strings
 *    Moves moves given fields into the message field.
 *    Useful for handling arbitrary fields.
 */

function moveFieldsToMessage(formData, fields) {
    for (let f of fields.slice().reverse()) {
        if (formData.has(f)) {
            formData.set(
                "message",
                `${f}: ${formData.get(f) || ""}\n\n${formData.get("message") || ""}`,
            )
            formData.delete(f)
        }
    }
}

export default moveFieldsToMessage
