/* User defined data
 *
 * Input user data here.
 * Ensure each item is defined, at least as an empty string.
 */
import React from "react"
import styled, { keyframes } from "styled-components"
import { Breakpoints } from "./src/util/breakpoints"
import useSiteMetadata from "./src/hooks/use-site-metadata"
import useUserPreferences from "./src/hooks/use-user-preferences"

import TabLink from "./src/components/tab-link"
import CustomForm from "./src/components/custom-form"
import DisplayItemList from "./src/components/display-item-list"
import TableItemList from "./src/components/table-item-list"

import DynamicImage from "./src/components/dynamic-image"
import SlideShow from "./src/components/slideshow"
import TabList from "./src/components/tab-list"
import Title from "./src/components/title"

/**********************************************************************
 * Input client website customization below
 **********************************************************************/

const footer = (<>
  <p>
    All content on this website,
    including the Website Template Project logo,
    is &copy; 2020&ndash;2022&nbsp;
    <a href="https://opensocialresources.com/" target="_blank" rel="noreferrer">
      Open Social Resources
    </a>. All rights reserved.
  </p>
</>)

/* Tab info and settings
 * Any tabs you want.
 *
 * For special tabs and components, you'll need to adjust imports in this file
 *
 * Use ProgressiveImage for images over img
 */

const aboutTab = {
  slug: "about",
  name: "About",
  content: (<>
    <h2>About us</h2>
    <p>
      Open Social Resources is a non-profit devoted to
      improving the community through software.
    </p>
    <p>
      Check out our first project: the&nbsp;
      <a href="https://website-template.opensocialresources.com/">
        Website Template Project</a>.
      The Website Template Project makes it easier for small business owners to
      get started with a web presence.
    </p>
    <h2>Who we are</h2>

    <DisplayItemList items={new Map([

      ["Mohammed Abouzahr, PhD", new Map(Object.entries({
        primaryInfo: (<>
          {/* The price of a menu item or a person's title for a bio */}
          <i>Founder</i>
        </>), description: (<>
          {/* A description of the item */}
          Mohammed started Open Social Resources
          with a vision to thrive by helping communities flourish.
          Apart from OSR,
          Mohammed enjoys spending time with loved ones, cooking, and logic puzzles.
        </>), image: "mohammed.jpg"
      }))],

      ["Grey Abouzahr", new Map(Object.entries({
        primaryInfo: (<>
          {/* The price of a menu item or a person's title for a bio */}
          <i>Outreach & Support Specialist</i>
        </>), description: (<>
          {/* A description of the item */}
          Grey joined the OSR team out of a desire to
          empower and strengthen local communities.
          Outside of her role at OSR,
          Grey enjoys playing Dungeons & Dragons, reading, and
          spending quality time with friends and family.
        </>), image: "grey.jpg"
      }))],

      ["Bumi", new Map(Object.entries({
        primaryInfo: (<>
          {/* The price of a menu item or a person's title for a bio */}
          <i>Director of Chicken Jerky</i>
        </>), description: (<>
          {/* A description of the item */}
          Bumi is happy to share his expertise in support of&nbsp;
          <strike style={{color: "dimgrey"}}>
            eating things he's not supposed to
          </strike> his community.
          In addition to&nbsp;
          <strike style={{color: "dimgrey"}}>
            getting belly rubs
          </strike> his time at OSR,
          Bumi loves&nbsp;
          <strike style={{color: "dimgrey"}}>
            nomming on chicken jerky
          </strike> jazz and long walks on the beach.
        </>), image: "bumi.jpg"
      }))],

    ])} />
  </>),
}

const projectsTab = {
  slug: "projects",
  name: "Projects",
  content: (<>
    <h2>Projects</h2>

    <DisplayItemList items={new Map([

      ["Arabic Examples to Terms", new Map(Object.entries({
        link: "https://gitlab.com/momosa/arabic-examples-to-terms",
        primaryInfo: (<>
          {/* The price of a menu item or a person's title for a bio */}
          <p><i>Associate Arabic sentences with terms in a dictionary.</i></p>
        </>), description: (<>
          {/* A description of the item */}
          <a
            href="https://gitlab.com/momosa/arabic-examples-to-terms"
            target="_blank"
            rel="noreferrer">
            https://gitlab.com/momosa/arabic-examples-to-terms</a>
          <p>
            Given an Arabic sentence,
            have a trained AI answer the question, <i>Of
            which Arabic terms is this sentence is a good
            example?</i> Can be used with any provided dictionary.
            We created this tool in cahoots with <a
              href="https://livingarabic.com/"
              target="_blank"
              rel="noreferrer">
              The Living Arabic Project</a>.
          </p>
        </>), image: "living_arabic_logo.png"
      }))],

      ["Website Template Project", new Map(Object.entries({
        link: "https://websitetemplateproject.org",
        primaryInfo: (<>
          {/* The price of a menu item or a person's title for a bio */}
          <p><i>Quickly create professional websites for small businesses</i></p>
        </>), description: (<>
          {/* A description of the item */}
          <a
            href="https://websitetemplateproject.org"
            target="_blank"
            rel="noreferrer">
            https://websitetemplateproject.org</a>
          <p>
            Provides a fast and easy way to create websites with the
            features businesses need:
            Speed, mobile-compatibility, search engine optimization, and more.
          </p>
        </>), image: "website_template_project_logo.png"
      }))],

      ["Logic Forall", new Map(Object.entries({
        primaryInfo: (<>
          {/* The price of a menu item or a person's title for a bio */}
          <p><i>An API for generating categorical syllogisms</i></p>
        </>), description: (<>
          {/* A description of the item */}
          <a
            href="http://logicforall.org/"
            target="_blank"
            rel="noreferrer">
            http://logicforall.org/</a>
          <p>
            Generate statements, syllogisms, and exercises in categorical logic.
            This is the first and only tool that allows you to specify soundess
            in addition to validity.
          </p>
        </>), image: "logic_forall_logo.png"
      }))],

    ])} />
  </>),
}

const donateTab = {
  slug: "donate",
  name: "Donate",
  content: (<>
    <h2>Donate</h2>
    <p>
      Coming soon! We'll set up this donation page as we transition
      from a social enterprise into a non-profit.
      In the mean time, please&nbsp; 
      <TabLink to="/contact">contact us</TabLink>
      &nbsp;using the contact form if you're interested in donating.
      We'll let you know as soon as we have everything set up.
    </p>
  </>),
}

const documentsTab = {
  slug: "documents",
  name: "Documents",
  content: (<>
    <TableItemList
      title={"Nonprofit corporation documents"}
      items={[
        [<a href="/docs/articles_of_incorporation.pdf" target="_blank" rel="noreferrer">
          Articles of Incorporation
        </a>],
        [<a href="/docs/bylaws.pdf" target="_blank" rel="noreferrer">
          Bylaws
        </a>],
        [<a href="/docs/conflict_of_interest_policy.pdf" target="_blank" rel="noreferrer">
          Conflict of Interest Policy
        </a>],
      ]}
    />

    <TableItemList
      title={"Financial records and tax documents"}
      items={[
        ["No documents yet"],
      ]}
    />
  </>),
}

const contactTab = {
  slug: "contact",
  name: "Contact",
  content: (<>
    <h2>Contact</h2>
    <CustomForm
      debug={process.env.GATSBY_ENVIRONMENT !== "production"}
      id="my-form"
      action={"https://formspree.io/mqkydkee"}
      fields={[
        new Map(Object.entries({
          fieldName: "name",
          required: true,
          label: "Name",
          input: "text",
        })),
        new Map(Object.entries({
          fieldName: "email",
          required: true,
          label: "Email",
          input: "email",
        })),
        new Map(Object.entries({
          fieldName: "message",
          required: true,
          label: "Message",
          input: "textarea",
        })),
      ]}
      formFields={["name", "email", "message"]}
    />
  </>),
}

const tabs = [
  aboutTab,
  projectsTab,
  donateTab,
  documentsTab,
  contactTab,
]

/*******************************************************************************
 *
 * Business Splash
 *
 * Defined here to handle a lot of customization.
 * Consider relocating to components once we have themes.
 *
 ******************************************************************************/

const Separator = styled.hr`
  border-top: 1px solid lightgray;
`

// Need to move the id marker up so navigation is visible
// Use only if bottom tab list present
const TabContentPlaceholder = styled.div`
  position: relative;
  top: -3em;
`
const StyledBusinessIdentifiers = styled.div`
  width: 100%;
  display: inline-grid;
  box-sizing: border-box;
  grid-template-columns: ${props => props.shouldUseIconLogo ?
    'min-content auto' :
    'auto'
  };
  column-gap: 15px;
  justify-content: start;
  align-items: center;
  grid-template-areas: ${props => props.shouldUseIconLogo ?
    '"icon title" "description description"' :
    '"title" "description"'
  };

  @media ${Breakpoints.smallOrLarger} {
    padding: 15px;
    padding-top: 0;
    row-gap: 7px;
    grid-template-columns: min-content auto min-content;
    grid-template-areas:
      "icon        title"
      "icon        description";
  }
`

const TitleContainer = styled.div`
  min-width: 60vw;
  grid-area: title;
`

const IconContainer = styled.div`
  grid-area: icon;
  height: 70px;
  width: 70px;

  @media ${Breakpoints.smallOrLarger} {
    height: 120px;
    width: 120px;
    padding-right: 15px;
    margin-bottom: 8px;
  }
`

const SlideshowContainer = styled.div`
  background-color: var(--backgroundColor);
  @media ${Breakpoints.smallOrLarger} {
    margin: auto;
    max-width: 90%;
  }
  @media ${Breakpoints.largeOrLarger} {
    max-width: 80%;
  }
`

const slideLeft = keyframes`
  from {
    margin-left: 100%;
    width: 300%;
  }
  to {
    margin-left: 0%;
    width: 100%;
  }
`

const DescriptionContainer = styled.p`
  grid-area: description;
  font-style: italic;
  font-size: larger;
  animation: ${slideLeft} 1s ease-out;

  @media ${Breakpoints.smallOrLarger} {
    margin: 0;
    vertical-align: middle;
  }
`

function BusinessIdentifiers(props) {
  const siteMetadata = useSiteMetadata()
  return (
    <StyledBusinessIdentifiers
      shouldUseIconLogo={true}
      data-qa={props["data-qa"] || "BusinessIdentifiers"}
    >

      <IconContainer data-qa={"BusinessIcon"}>
        <DynamicImage
          src="icon.png"
          alt="Business icon"
          data-qa="BusinessIcon"
          shouldShowBackground={false}
        />
      </IconContainer>

      <TitleContainer>
      {/*
        <h1>
          <DynamicImage
            src="banner_logo.png"
            alt={siteMetadata.title}
            data-qa="BusinessBanner"
            shouldShowBackground={false}
          />
        </h1>
      */}
        <Title
          text={siteMetadata.title}
          data-qa={"BusinessTitle"}
        />
      </TitleContainer>

      <DescriptionContainer data-qa={"BusinessDescription"}>
        {siteMetadata.description}
      </DescriptionContainer>

    </StyledBusinessIdentifiers>

  )
}

function TabPageSplash(props) {
  const userPreferences = useUserPreferences()

  return (<>
    <BusinessIdentifiers />
    <SlideshowContainer>
      <SlideShow
        images={userPreferences.splashImages}
        data-qa={"SplashImages"}
      />
    </SlideshowContainer>


    {/* Ensure bottom tab list is visible when switching tabs
        Only use with bottom tab list
    */}
    <TabContentPlaceholder id="TabContent" />

    <div role={"navigation"}>
      <TabList
        activeTab={props.activeTab}
        tabs={tabs}
      />
    </div>

    <Separator />
  </>)
}

export default function getUserContent() {
  return {
    footer,
    tabs,
    BusinessIdentifiers,
    TabPageSplash,
  }
}
