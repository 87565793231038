/* CustomForm
 * Props:
 *  id (required)
 *  onSubmit (optional, default is to use send form to action endpoint)
 *  action (optional, url)
 *  method (optional)
 *  debug (optional boolean. Will output data to an alert instead of sending)
 *  fields: array of maps (required)
 *    fieldName: string (required)
 *    required: boolean (required)
 *    label: string (required)
 *    input: string (required, "textarea" or input type like "tel")
 *  formFields: array (required, fields that will not be lumped into message)
 *
 * Usage:
 *    <CustomForm
 *      debug={true}
 *      id="my-form"
 *      action={"https://formspree.io/your-endpoint"}
 *      fields={[
 *        new Map(Object.entries({
 *          fieldName: "name",
 *          required: true,
 *          label: "Name",
 *          input: "text",
 *        })),
 *        new Map(Object.entries({
 *          fieldName: "email",
 *          required: true,
 *          label: "Email",
 *          input: "email",
 *        })),
 *        new Map(Object.entries({
 *          fieldName: "phone",
 *          required: false,
 *          label: "Phone number",
 *          input: "tel",
 *        })),
 *        new Map(Object.entries({
 *          fieldName: "message",
 *          required: true,
 *          label: "Message",
 *          input: "textarea",
 *        })),
 *      ]}
 *      formFields={["name", "email", "message"]}
 *    />
 *  ))
 *
 */

import React, { useState } from "react"
import styled from "styled-components"
import { Breakpoints } from "../util/breakpoints"
import { getElementById } from "../util/get-element"
import getValidationErrors from "../util/form-validation"
import moveFieldsToMessage from "../util/form-manipulation"
import useEventListener from "../hooks/use-event-listener"
import ValidationErrors from "./validation-errors"
import AccentedButton from "./accented-button"

const FormInputs = styled.div`
  display: inline-grid;
  grid-template-columns: auto;
  width: 100%;
  row-gap: 10px;
  padding-bottom: 10px;
`

const FormItem = styled.label`
  display: inline-grid;
  grid-template-columns: auto;
`

const StyledForm = styled.form`
  @media ${Breakpoints.smallAndLarger} {
    width: 80%;
    margin: auto;
  }
`

/* Default browser behavior is to
 * Submit a form whenever someone presses Enter.
 * This confuses users in multi-part forms.
 * Ensure the Submit button is keyboard-accessible
 * when suppressing implicit form submission.
 */
const suppressImplicitSubmit = e => {
  if (e.key === "Enter") {
    e.preventDefault()
  }
}

function TextArea(props) {
  const element = getElementById(props.identifier)
  useEventListener("keypress", suppressImplicitSubmit, element)
  return (
    <textarea
      id={props.identifier}
      form={props.formId}
      maxLength="1000"
      rows="10"
      name={props.field.get("fieldName")}
      aria-label={`Enter your ${props.field.get("label").toLowerCase()}`}
      data-qa={props["data-qa"] || "FormInput"}
    />
  )
}

function FormInput(props) {
  const element = getElementById(props.identifier)
  useEventListener("keypress", suppressImplicitSubmit, element)
  return (
    <input
      id={props.identifier}
      type={props.field.get("type") || "text"}
      name={props.field.get("fieldName")}
      aria-label={`Enter your ${props.field.get("label").toLowerCase()}`}
      data-qa={props["data-qa"] || "FormInput"}
    />
  )
}

function CustomForm(props) {
  const [status, setStatus] = useState("")
  const [formErrors, setFormErrors] = useState([])

  const inputFields = props.fields.map(field => {
    const fieldName = field.get("fieldName")
    const key = `${props.id}-${fieldName}`
    return (
      <FormItem key={key} data-qa="FormItem">
        {field.get("label")}{!field.get("required") && " (optional)"}
        {
          field.get("input") === "textarea" ? (
            <TextArea identifier={key} field={field} formId={props.id} />
          ) : (
            <FormInput identifier={key} field={field} />
          )
        }
      </FormItem>
    )
  })

  const debugMsg = <ValidationErrors
    errorMessages={[
      "DEBUG MODE",
      "This form will not send messages.",
      "Data that would be sent is shown in an alert.",
    ]}
    data-qa={"DebugMessage"}
  />

  const submitForm = e => {
    e.preventDefault()
    const form = e.target
    const data = new FormData(form)
    let messageFields = []
    let optionalFields = []
    for (let field of props.fields) {
      if (!props.formFields.includes(field.get("fieldName"))) {
        messageFields.push(field.get("fieldName"))
      }
      if (!field.get("required")) {
        optionalFields.push(field.get("fieldName"))
      }
    }
    const errs = getValidationErrors(data, optionalFields)
    setFormErrors(errs)

    if (errs.length === 0) {
      const xhr = new XMLHttpRequest()
      data.delete("_gotcha")
      moveFieldsToMessage(data, messageFields)
      xhr.open(form.method, form.action);
      xhr.setRequestHeader("Accept", "application/json");
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
          form.reset()
          setStatus("SUCCESS")
          alert("Thank you for your submission!")
        } else {
          setStatus("ERROR")
        }
      };

      if (props.debug) {
        const msgParts = []
        msgParts.push("The following message would be sent:")
        for (const [f, v] of data.entries()) {
          msgParts.push(`${f}: ${v}`)
        }
        alert(msgParts.join("\n"))
      } else {
        xhr.send(data)
      }

    }
  }

  return (
    <StyledForm
      id={props.id}
      onSubmit={props.onSubmit || submitForm}
      action={props.action}
      method={props.method || "POST"}
      data-qa={props["data-qa"] || "CustomForm"}
    >
      {props.debug && debugMsg}

      <FormInputs data-qa={"FormInputs"}>
        {inputFields}
      </FormInputs>

      {/* Honeypot spam filtering */}
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <input
        type="text"
        name="_gotcha"
        style={{display: "none"}}
        aria-hidden="true"
        data-qa={"FormHoneyPot"}
      />

      <ValidationErrors
        errorMessages={formErrors}
        data-qa={"FormErrors"}
      />

      {status === "SUCCESS" ?
        <p data-qa="ThankYouMessage">
          Thank you for your submission!
        </p> :
        <AccentedButton data-qa="SubmitButton">
          Submit
        </AccentedButton>
      }
      {status === "ERROR" && (
        <p data-qa="SubmissionErrorMessage">
          Uh, oh!
          Looks like something went wrong connecting to the form service.
        </p>
      )}
    </StyledForm>
  )

}

CustomForm.defaultProps = {
  email: "required",
  phone: null,
}

export default CustomForm
