/*
 * getValidationErrors
 * data should be of type FormData
 * optional is an optional array of optional field names
 */
function getValidationErrors(data, optional) {
    optional = optional || []
    let errs = []
    let validation = {
        name: {
            regex: /^.{4,64}$/,
            errmsg: "Please provide a name that's at least 4 characters.",
        },
        email: {
            // https://emailregex.com/
            // eslint-disable-next-line no-control-regex
            regex: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
            errmsg: "Please provide a valid email address.",
        },
        phone: {
            regex: /^(\+?1?[-\s]?)(\(\d{3}\)|\d{3})[-\s]?(\d{3})[-\s]?(\d{4})$/,
            errmsg: "Please provide a phone number of the form 123-456-7890.",
        },
        message: {
            regex: /^[\s\S]{20,1000}$/,
            errmsg: "Please provide a message between 20 and 1000 characters (about 140 words).",
        },
        _gotcha: {
            regex: /^$/,
            errmsg: "Bad robot!",
        },
    }
    for (let [key, value] of data.entries()) {
        if (validation[key]) {
          // It's false that either it's optional and there's no value or the regex passes
          if (!((optional.includes(key) && !value) || validation[key].regex.test(value))) {
              errs.push(validation[key].errmsg)
          }
        } else if (!value && !optional.includes(key)) {
            errs.push(`${key.charAt(0).toUpperCase()}${key.slice(1)} is required.`)
        }
    }
    return errs
}

export default getValidationErrors
