import React from "react"
import styled from "styled-components"

const ErrMsg = styled.p`
  color: var(--errorTextColor);
`

export default function ValidationErrors(props) {

  const errs = props.errorMessages.map(msg =>
    <ErrMsg key={msg} data-qa={"ValidationError"}>
      {msg}
    </ErrMsg>
  )

  return (
    <div data-qa={props["data-qa"] || "ValidationErrors"}>
      {errs}
    </div>
  )
}
